//CSS pulled from https://css.gg/
//sass-lint:disable-all
//shared css rules of all icons
.gg {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}

.gg--check {
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg--check::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

// Chevron & Transformations
@mixin chevron {
  border: 2px solid transparent;
  border-radius: 100px;
}

@mixin chevron-after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}

.gg--chevron-up,
.gg--chevron-down,
.gg--chevron-left,
.gg--chevron-right {
  @include chevron();

  &::after {
    @include chevron-after();
  }
}

.gg--chevron-down {
  transform: rotate(180deg);
}

.gg--chevron-left {
  transform: rotate(-90deg);
}

.gg--chevron-right {
  transform: rotate(90deg);
}

// Arrow & Transformations

@mixin arrow {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}

@mixin arrow-block {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}

@mixin arrow-after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
}

@mixin arrow-before {
  width: 2px;
  height: 10px;
  left: 8px;
  background: currentColor;
}

.gg--arrow-up,
.gg--arrow-down,
.gg--arrow-left,
.gg--arrow-right {
  @include arrow();

  &::after,
  &::before {
    @include arrow-block();
  }

  &::after {
    @include arrow-after();
  }

  &::before {
    @include arrow-before();
  }
}

.gg--arrow-down {
  transform: rotate(180deg);
}

.gg--arrow-left {
  transform: rotate(-90deg);
}

.gg--arrow-right {
  transform: rotate(90deg);
}

// Arrow-R & Transformations

@mixin arrow-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}

@mixin arrow-r-block {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}

@mixin arrow-r-after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 7px;
}

@mixin arrow-r-before {
  width: 2px;
  height: 16px;
  left: 10px;
  background: currentColor;
}

.gg--arrow-r-up,
.gg--arrow-r-down,
.gg--arrow-r-left,
.gg--arrow-r-right,
.gg--arrow-r-d-up-right {
  @include arrow-r();

  &::after,
  &::before {
    @include arrow-r-block();
  }

  &::after {
    @include arrow-r-after();
  }

  &::before {
    @include arrow-r-before();
  }
}

.gg--arrow-r-down {
  transform: rotate(180deg);
}

.gg--arrow-r-left {
  transform: rotate(-90deg);
}

.gg--arrow-r-right {
  transform: rotate(90deg);
}

.gg--arrow-r-d-up-right {
  transform: rotate(45deg);
}

// Ribbon & Transformations

.gg--ribbon-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
}
.gg--ribbon-up:before {
  width: 51%;
}
.gg--ribbon-up:after,
.gg--ribbon-up:before {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
}
.gg--ribbon-up:before {
  left: 0;
  border-top: 2px solid;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: skew(0deg, -30deg);
}
.gg--ribbon-up:after {
  right: 0;
  width: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: 2px solid;
  transform: skew(0deg, 30deg);
}

//------------------------------------------------------------
.gg--close {
  border: 2px solid transparent;
  border-radius: 40px;
}

.gg--close::after,
.gg--close::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 3px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px;
}

.gg--close::after {
  transform: rotate(-45deg);
}

//------------------------------------------------------------
.gg--search {
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
}

.gg--search::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 8px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 10px;
  left: 12px;
}

//------------------------------------------------------------
.gg--facebook {
  width: 20px;
  height: 20px;
}

.gg--facebook::after,
.gg--facebook::before {
  content: '';
  display: block;
  position: absolute;
  box-sizing: border-box;
}

.gg--facebook::before {
  width: 8px;
  height: 20px;
  border-left: 4px solid;
  border-top: 4px solid;
  border-top-left-radius: 5px;
  left: 6px;
}

.gg--facebook::after {
  width: 10px;
  height: 4px;
  background: currentColor;
  top: 7px;
  left: 4px;
  transform: skew(-5deg);
}

//------------------------------------------------------------
.gg--info {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}

.gg--info::after,
.gg--info::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}

.gg--info::after {
  bottom: 2px;
  height: 8px;
}

.gg--info::before {
  height: 2px;
  top: 2px;
}

//------------------------------------------------------------
.gg--link {
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
  margin: 9px 6px;
}

.gg--link::after,
.gg--link::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 10px;
  border: 2px solid;
  top: -4px;
}

.gg--link::before {
  border-right: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  left: -6px;
}

.gg--link::after {
  border-left: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  right: -6px;
}

//------------------------------------------------------------
.gg--maximize-alt {
  width: 14px;
  height: 14px;
  box-shadow:
    -6px -6px 0 -4px,
    6px 6px 0 -4px,
    6px -6px 0 -4px,
    -6px 6px 0 -4px;
}

.gg--maximize-alt::after,
.gg--maximize-alt::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 22px;
  height: 2px;
  border-left: 8px solid;
  border-right: 8px solid;
  transform: rotate(-45deg);
  bottom: 6px;
  left: -4px;
}

.gg--maximize-alt::before {
  transform: rotate(45deg);
}

//------------------------------------------------------------
.gg--phone::after,
.gg--phone::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg--phone::after {
  width: 18px;
  height: 18px;
  border-top-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 12px;
  border-left: 4px solid;
  border-bottom: 4px solid;
  left: 2px;
  bottom: 2px;
  background:
    linear-gradient(to left, currentColor 10px, transparent 0) no-repeat right
      11px/6px 4px,
    linear-gradient(to left, currentColor 10px, transparent 0) no-repeat -1px 0/4px
      6px;
}

.gg--phone::before {
  width: 20px;
  height: 20px;
  border: 6px double;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  transform: rotate(-45deg);
  bottom: 2px;
  left: 2px;
}

//------------------------------------------------------------
.gg--twitter {
  width: 20px;
  height: 20px;
}

.gg--twitter::after,
.gg--twitter::before {
  content: '';
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 4px;
}

.gg--twitter::before {
  width: 9px;
  height: 14px;
  border-left: 4px solid;
  border-bottom: 4px solid;
  border-bottom-left-radius: 6px;
  background: linear-gradient(to left, currentColor 12px, transparent 0)
    no-repeat center 2px/10px 4px;
  top: 4px;
}

.gg--twitter::after {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 20px;
  top: 2px;
  box-shadow:
    7px 4px 0,
    7px 12px 0;
}

.gg--information-circle {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}

.gg--information-circle::after,
.gg--information-circle::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}

.gg--information-circle::after {
  bottom: 2px;
  height: 8px;
}

.gg--information-circle::before {
  height: 2px;
  top: 2px;
}

//
.gg--check-box {
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--check-box::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg--radio-button-unselected {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}

.gg--radio-button-unselected-focused {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-radius: 100px;
}

.gg--radio-button-selected,
.gg--radio-button-selected::after {
  display: block;
  box-sizing: border-box;
  border-radius: 100px;
}

.gg--radio-button-selected {
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}

.gg--radio-button-selected::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: currentColor;
  top: 4px;
  left: 4px;
}

.gg--remove {
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 10px;
}

.gg--plus,
.gg--plus::after {
  border-radius: 10px;
}

.gg--plus {
  margin-top: -2px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
}

.gg--plus::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}

.gg--calendar,
.gg--calendar::before {
  display: block;
  box-sizing: border-box;
}

.gg--calendar {
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-top: 4px solid;
  border-radius: 3px;
}

.gg--calendar::before {
  content: '';
  position: absolute;
  width: 10px;
  border-radius: 3px;
  left: 2px;
  background: currentColor;
  height: 2px;
  top: 2px;
}

.gg--check-circle {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}

.gg--check-circle::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-color: currentColor;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg--time {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px currentColor;
}

.gg--time::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 7px;
  height: 7px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  top: 1px;
  left: 5px;
}

.gg--close-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--close-r::after,
.gg--close-r::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 3px;
}

.gg--close-r::after {
  transform: rotate(-45deg);
}

.gg--location-pin {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 100% 100% 0 100%;
  border: 2px solid;
  margin-top: -4px;
}

.gg--location-pin::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  top: 3px;
  left: 3px;
  border-radius: 40px;
}

.gg--mail,
.gg--mail::after {
  display: block;
  box-sizing: border-box;
  height: 14px;
  border: 2px solid;
}

.gg--mail {
  overflow: hidden;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 18px;
  border-radius: 2px;
}

.gg--mail::after {
  content: '';
  position: absolute;
  border-radius: 3px;
  width: 14px;
  transform: rotate(-45deg);
  bottom: 3px;
  left: 0;
}

.gg--ratio {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 20px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--ratio::after,
.gg--ratio::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
}

.gg--ratio::before {
  border-bottom: 2px solid;
  border-right: 2px solid;
  top: 8px;
  left: 12px;
}

.gg--ratio::after {
  border-top: 2px solid;
  border-left: 2px solid;
  top: 2px;
  left: 2px;
}

.gg--lock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 11px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -12px;
}

.gg--lock::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 10px;
  border-radius: 2px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  left: -4px;
  top: 9px;
}

.gg--menu {
  transform: scale(var(--ggs, 1));
}

.gg--menu,
.gg--menu::after,
.gg--menu::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}

.gg--menu::after,
.gg--menu::before {
  content: '';
  position: absolute;
  top: -6px;
}

.gg--menu::after {
  top: 6px;
}

.gg--pen {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 14px;
  height: 4px;
  border-right: 2px solid transparent;
  box-shadow:
    0 0 0 2px,
    inset -2px 0 0;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-right: -2px;
}

.gg--pen::after,
.gg--pen::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg--pen::before {
  background: currentColor;
  border-left: 0;
  right: -6px;
  width: 3px;
  height: 4px;
  border-radius: 1px;
  top: 0;
}

.gg--pen::after {
  width: 8px;
  height: 7px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 7px solid;
  left: -11px;
  top: -2px;
}

.gg--play-button {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}

.gg--play-button::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

.gg--plus,
.gg--plus::after {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 10px;
}

.gg--plus {
  margin-top: -2px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
}

.gg--plus::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}

.gg--printer {
  background:
    linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 0 10px/6px
      2px,
    linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 14px
      10px/6px 2px,
    linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4px 4px/2px
      2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
  border: 2px solid transparent;
  border-bottom: 0;
  box-shadow:
    inset 0 2px 0,
    inset 2px 2px 0,
    inset -2px 2px 0,
    inset -2px 2px 0;
}

.gg--printer::after,
.gg--printer::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  border: 2px solid;
  left: 4px;
}

.gg--printer::before {
  height: 6px;
  top: -4px;
}

.gg--printer::after {
  height: 8px;
  top: 8px;
}

.gg--chat {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-bottom: 0;
  box-shadow:
    -6px 8px 0 -6px,
    6px 8px 0 -6px;
}
.gg--chat::after,
.gg--chat::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
}
.gg--chat::before {
  border: 2px solid;
  border-top-color: transparent;
  border-bottom-left-radius: 20px;
  right: 4px;
  bottom: -6px;
  height: 6px;
}
.gg--chat::after {
  height: 2px;
  background: currentColor;
  box-shadow: 0 4px 0 0;
  left: 4px;
  top: 4px;
}

.gg--external {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  box-shadow:
    -2px 2px 0 0,
    -4px -4px 0 -2px,
    4px 4px 0 -2px;
  margin-left: -2px;
  margin-top: 1px;
}
.gg--external::after,
.gg--external::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: -4px;
}
.gg--external::before {
  background: currentColor;
  transform: rotate(-45deg);
  width: 12px;
  height: 2px;
  top: 1px;
}
.gg--external::after {
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  top: -4px;
}

.gg--link {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
}
.gg--link::after,
.gg--link::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 10px;
  border: 2px solid;
  top: -4px;
}
.gg--link::before {
  border-right: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  left: -6px;
}
.gg--link::after {
  border-left: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  right: -6px;
}

.gg--check-box-outline {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--check-box-outline-focused {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-radius: 4px;
}

.gg--bookmark,
.gg--bookmark::after {
  border-top-right-radius: 3px;
}
.gg--bookmark {
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 3px;
  overflow: hidden;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
}
.gg--bookmark::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  top: 9px;
  left: -1px;
}

.gg--plus-circle {
  border: 2px solid;
  border-radius: 22px;
}
.gg--plus-circle::after,
.gg--plus-circle::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}
.gg--plus-circle::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg--smartphone {
  background: linear-gradient(to left, currentColor 5px, transparent 0)
    no-repeat 4px 12px/2px 2px;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
}

.gg--awards {
  display: block;
  width: 16px;
  height: 20px;
  border: 4px solid transparent;
  border-top: 12px solid transparent;
  border-bottom: 0;
  box-shadow:
    inset 4px 0 0 -2px,
    inset -4px 0 0 -2px;
}
.gg--awards::after,
.gg--awards::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  display: block;
  border: 2px solid;
}
.gg--awards::before {
  width: 14px;
  height: 14px;
  border-radius: 22px;
  top: -12px;
  left: -3px;
}
.gg--awards::after {
  width: 6px;
  height: 6px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-bottom-left-radius: 1px;
  border-top-right-radius: 1px;
  transform: rotate(45deg);
  bottom: -3px;
  left: 1px;
}

.gg--trophy {
  width: 10px;
  height: 12px;
  border: 2px solid;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.gg--trophy::after,
.gg--trophy::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg--trophy::before {
  box-shadow:
    inset 0 0 0 2px,
    0 2px 0 0;
  bottom: -4px;
  width: 6px;
  height: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  left: 0;
}
.gg--trophy::after {
  width: 16px;
  height: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
  left: -5px;
  top: 0;
}

.gg--pin-alt {
  width: 2px;
  height: 8px;
  border-radius: 3px;
  transform: scale(var(--ggs, 1));
  background: currentColor;
}
.gg--pin-alt::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 6px double;
  border-radius: 100px;
  top: -10px;
  left: -5px;
}
.gg--chevron-double-right::after,
.gg--chevron-double-right::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  right: 6px;
}
.gg--chevron-double-right::after {
  right: 11px;
}

.gg--readme {
  width: 2px;
  height: 17px;
  border-bottom: 4px solid;
  border-radius: 4px;
}
.gg--readme::after,
.gg--readme::before {
  content: '';
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 12px;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  bottom: -1px;
  background:
    linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center
      5px/4px 2px,
    linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center
      2px/4px 2px,
    linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center
      8px/4px 2px;
}
.gg--readme::before {
  border-top-right-radius: 3px;
  left: -8px;
}
.gg--readme::after {
  border-top-left-radius: 3px;
  right: -8px;
}

.gg--play-button-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg--play-button-alt::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

.gg--heart,
.gg--heart::after {
  border: 2px solid;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  width: 10px;
  height: 8px;
  border-bottom: 0;
}

.gg--heart {
  box-sizing: border-box;
  position: relative;
  transform: translate(
      calc(-10px / 2 * var(--ggs, 1)),
      calc(-6px / 2 * var(--ggs, 1))
    )
    rotate(-45deg) scale(var(--ggs, 1));
  display: block;
}

.gg--heart::after,
.gg--heart::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg--heart::after {
  right: -9px;
  transform: rotate(90deg);
  top: 5px;
}

.gg--heart::before {
  width: 11px;
  height: 11px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  left: -2px;
  top: 3px;
}

//sass-lint:enable-all
