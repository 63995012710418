// General header styling mixin
@mixin header-style(
  $baseFontSize,
  $baseLineHeight,
  $mediaFontSize: null,
  $mediaLineHeight: null
) {
  font-family: $font-family--serif;
  color: $heading-color;
  font-weight: 700;

  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);

  // Adjust for larger screens if mediaFontSize and mediaLineHeight are provided
  @if $mediaFontSize and $mediaLineHeight {
    @include media-breakpoint-up(m) {
      font-size: rem($mediaFontSize);
      line-height: rem($mediaLineHeight);
    }
  }
}

// Header mixins using the general header styling mixin with specified sizes
@mixin make-h1 {
  @include header-style(32, 42, 42, 50);
}

@mixin make-h2 {
  @include header-style(28, 36, 34, 40);
  margin-bottom: $spacer--l;
}

@mixin make-h3 {
  @include header-style(24, 32, 28, 34);
  margin-top: $spacer--4xl;
  margin-bottom: $spacer;
}

@mixin make-h4 {
  @include header-style(20, 28, 24, 28);
  margin-top: $spacer--xl;
  margin-bottom: $spacer;
}

@mixin make-h5 {
  @include header-style(18, 26, 20, 24);
  margin-top: $spacer--xl;
  margin-bottom: $spacer;
}

@mixin make-h6 {
  @include header-style(18, 26, 20, 24);
  margin-top: $spacer--xl;
  margin-bottom: $spacer;
}

@mixin make-small-heading {
  @include header-style(20, 26, 24, 31);
  margin-top: $spacer--xl;

  @include media-breakpoint-up(l) {
    margin-top: 0;
  }
}
