@import '../../required';

/* This is need for an ie11 bugfix where the videos are causing spacing issues at the bottom of the page*/
.home {
  // sass-lint:disable-all
  #content {
    overflow: hidden;
  }
  // sass-lint:enable-all
}

/* Removes arrow icon from hero CTA button */
.c-hero__main
  > .c-cta
  > .c-cta__inner
  > .c-cta__content
  > .c-cta__button
  > .c-button {
  .o-icon {
    display: none;
  }
}

.c-profile-block__name {
  font-size: unset;
}

.c-hero {
  padding-top: 3rem;
  position: relative;
  background-repeat: no-repeat;
  background-color: $iceberg;

  @include media-breakpoint-down(s) {
    padding-bottom: 0;
  }

  .c-hero-img__mobile {
    display: block;

    @include media-breakpoint-up(m) {
      display: none;
    }
  }

  .c-hero__main {
    @include media-breakpoint-only(m) {
      text-align: center;
    }
  }

  .c-hero__content {
    z-index: 2;

    @include media-breakpoint-down(m) {
      padding-bottom: $spacer--l;
    }
  }
  .c-button {
    width: 100%;
    max-width: 27rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 rem(4) rem(8) rgba($ocean, 0.2);
  }
  .c-action-list {
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 2;

    @include media-breakpoint-down(s) {
      background-color: $white;
    }
  }
  .c-action-block__title {
    font-size: rem(20);
    display: block;
    margin-bottom: 0.75rem;
  }
  .c-action-block__copy {
    // color: $white;
    font-size: 1rem;
    line-height: rem(21);
  }

  .c-action-block {
    .o-image {
      display: block;
      min-width: rem(24);
      margin-top: rem(2);
    }
  }

  @include media-breakpoint-down(m) {
    .c-action-list {
      padding-top: 1.5rem;
    }

    .c-action-block {
      background-color: $white;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 1rem;
      max-width: 27rem;
    }
  }

  @include media-breakpoint-down(s) {
    .c-action-block {
      flex-direction: row;
      text-align: left;
      border-bottom: rem(1) solid $gray-20;
      border-radius: 0;

      .c-action-block__content {
        padding-left: $spacer--m;
      }
    }

    .c-action-list__item {
      &:last-of-type {
        .c-action-block {
          border: none;
        }
      }
    }
  }

  @include media-breakpoint-up(l) {
    background-image: url('/wp-content/uploads/homepage-hero-1.png');
    background-position: top right;
    padding-bottom: 0;

    .c-button {
      width: auto;
      margin-left: 0;
    }

    .c-hero__after {
      background-color: $white;
      margin-top: 2rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .c-action-list {
      align-items: stretch;
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
    }

    .c-action-list__items {
      flex-direction: row;
    }

    .c-action-list__item {
      flex: 0 1 33%;
      border-right: rem(1) solid $light;
      &:last-child {
        border: 0;
      }
    }

    .c-action-block__image {
      padding-left: $spacer--s;
      padding-right: $spacer--s;
    }

    .c-action-block {
      border-radius: 0;
      padding: 0;
      margin: 0;
      text-align: left;
      flex-direction: row;
      align-items: flex-start;
    }

    .c-action-block__title {
      font-size: rem(22);
    }
  }

  @media only screen and (min-width: rem(880)) and (max-width: rem(960)) {
    background-position: 170% 0;
  }

  @media only screen and (min-width: rem(961)) and (max-width: rem(1080)) {
    background-position: 130% 0;
  }

  @media only screen and (min-width: rem(2000)) {
    background-position: 75% 0;
  }

  @include media-breakpoint-only(m) {
    .c-action-list__items {
      @include make-row;
    }

    .c-action-list__item {
      @include make-col-ready($spacer--l);
      @include make-col(6);

      &:last-of-type {
        .c-action-block {
          min-height: rem(145);
          align-self: center;
        }
      }
    }
  }

  .c-figure {
    margin: 0 auto;
  }

  .c-video__link {
    display: flex;
    text-decoration: none;
    color: $gray;
    align-items: center;

    @include media-breakpoint-down(s) {
    }
  }

  .c-video__details {
    height: rem(84);
    flex: 0 0 rem(113);
    position: relative;
  }

  .c-video__image {
    width: auto;
    height: rem(84);
    object-fit: cover;
  }

  .c-video__content {
    padding-left: $spacer;
    position: relative;
    flex: 1 1 auto;
  }

  .c-video__caption {
    text-align: left;
    margin: 0;
    color: $ocean;
    text-decoration: none;
    font-size: 1rem;
  }

  .c-video__icon {
    --ggs: 1;
  }
}

.c-hero__title,
.c-hero__description {
  @include media-breakpoint-up(l) {
    width: 50%;
    min-width: 36rem;
  }

  @include media-breakpoint-between(m, l) {
    width: 40%;
    min-width: 34rem;
  }

  @include media-breakpoint-down(m) {
    max-width: 100%;
    margin: 0 auto;
  }
}

.c-hero__description {
  margin-bottom: $spacer--xl;
}

.c-page__content {
  background-color: $white;
}

.c-homepage__buttons {
  padding-top: $spacer--xl;
  padding-bottom: $spacer--xl;

  .c-section__content {
    display: flex;
    flex-direction: column;
  }
  .c-icon__text {
    display: flex;
    align-items: center;
  }
  .c-button {
    font-size: rem(18);
    color: $mariner;
    line-height: 1.05;
    background-color: $white;
    border: none;
    flex: 0 0 30.33%;
    margin-top: 0;
    margin-bottom: 3%;
    flex-direction: column;
    padding: $spacer--l $spacer;
    box-shadow: $box-shadow;
    text-transform: unset;
    letter-spacing: unset;
    transition: all 0.2s ease-in-out;

    @include media-breakpoint-down(s) {
      margin-bottom: 5%;
    }

    &:hover {
      box-shadow: $box-shadow-dark;
      transform: translateY(-$spacer--2xs);

      .c-icon__text {
        color: $ocean;
      }
    }
  }
  .o-image {
    margin-bottom: 1rem;
    width: rem(40);
  }

  @include media-breakpoint-up(m) {
    .c-section__content {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .c-button {
      flex: 0 0 46%;
      margin-bottom: 4%;
    }
  }

  @include media-breakpoint-up(l) {
    .c-section__content {
      margin-top: 3rem;
    }
    .c-button {
      font-size: 1.4375rem;
      flex: 0 0 30.33%;
      margin-top: 0;
      margin-bottom: 3%;
      padding: $spacer--l $spacer--m;
    }
  }
}

.c-video {
  text-align: left;

  .c-homepage__experience &,
  .c-homepage__help & {
    text-align: left;
  }
}

.c-homepage__experience {
  position: relative;
  background-color: $iceberg;
  overflow: hidden;

  .l-container {
    position: relative;
    z-index: 1;
  }

  .c-media-block__content .c-wysiwyg {
    margin-left: auto;
    margin-right: auto;

    p {
      margin-bottom: $spacer--2xs;
    }
  }

  .c-media-block__subtitle {
    color: $gray-80;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: rem(2);
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-down(s) {
      text-align: left;
    }

    &::before {
      content: '';
      display: block;
      background-color: $peach;
      height: rem(2);
      width: rem(48);
    }
  }

  .c-media-block__content {
    text-align: center;
    margin-bottom: $spacer--2xl;

    @include media-breakpoint-down(s) {
      text-align: left;
    }

    p:not(.c-media-block__subtitle) {
      text-align: left;
    }

    .c-button {
      @include media-breakpoint-down(m) {
        width: 100%;
        max-width: 30rem;
      }
    }
    @include media-breakpoint-up(l) {
      text-align: left;
      margin-bottom: 0;
    }
  }

  .c-media-block__content h2 {
    margin-top: 0;
  }

  .c-media-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .c-media-block--media-right .c-media-block__media {
    order: 2;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(s) {
      text-align: left;
    }
  }

  .c-container .c-content p {
    @include media-breakpoint-up(l) {
      font-size: rem(21);
    }
  }

  @include media-breakpoint-up(l) {
    background-image: url('/wp-content/themes/asbestos/assets/img/home/home-bg-plus-marks.svg');
    background-repeat: no-repeat;
    background-position: 5% 95%;
    background-size: rem(230);
    .c-media-block {
      flex-direction: row;
    }

    .c-media-block__subtitle {
      text-align: left;
    }
    .c-media-block--media-right .c-media-block__media {
      max-width: none;
    }
  }
}

.c-homepage__teams {
  background-color: $white;
  padding: $spacer--2xl $spacer--xl $spacer--xl;
  border-radius: $spacer--s;
  margin-top: $spacer;
  text-align: center;
  h3 {
    font-size: rem(35);
    margin-top: 0;
  }
  .c-content p {
    line-height: 1.4;
    font-size: rem(18);
    text-align: left;
  }
  .c-profile-block {
    align-items: center;
    margin-top: $spacer--l;
    margin-bottom: $spacer--l;
  }
  .c-profile-block__content {
    padding-left: $spacer--m;
  }
  .c-profile-block__name {
    font-size: 1.4rem;
  }
  .c-profile-block__title {
    font-style: italic;
  }
  .c-media {
    text-align: left;
  }
  > .c-button {
    margin-top: $spacer--l;
    display: inline-block;
    width: 100%;
    max-width: 30rem;
  }
  @include media-breakpoint-down(m) {
    .c-content .c-button {
      display: none;
    }
  }
  @include media-breakpoint-up(m) {
    .c-media {
      display: flex;
      flex-wrap: wrap;
    }
    .c-profile-block {
      flex: 0 0 50%;
    }
  }
  @include media-breakpoint-up(l) {
    margin-top: $spacer--2xl;
    display: flex;
    padding: $spacer--xl;
    align-items: center;
    text-align: left;
    h3 {
      font-size: 1.5rem;
    }
    .c-content {
      flex: 0 1 41.667%;
    }
    .c-media {
      flex: 0 1 58.333%;
      padding-left: $spacer--xl;
    }
    .c-profile-block {
      margin-top: $spacer;
      margin-bottom: $spacer;
    }
    .c-profile-block__name {
      font-size: rem(22);
    }
    .c-profile-block__content {
      padding-left: $spacer;
    }
    > .c-button {
      display: none;
    }
  }
}

.c-home__featured-in {
  text-align: center;

  h3 {
    margin-top: $spacer--4xl;
  }
}

.c-home__featured-in__logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .c-home__logo-container {
    flex: 0 1 auto;
    padding: $spacer;
  }

  @include media-breakpoint-up(xl) {
    flex-wrap: nowrap;
  }
}

.c-section--primary-dark {
  background-color: $primary-dark;
}

.c-homepage__help {
  @include media-breakpoint-down(m) {
    text-align: left;
  }

  .c-media-block {
    margin-top: $spacer--xl;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .c-media-block__subtitle {
    color: $gray-80;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: rem(2);
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-down(s) {
      text-align: left;
    }

    &::before {
      content: '';
      display: block;
      background-color: $peach;
      height: rem(2);
      width: rem(48);
    }
  }

  .c-cta {
    margin-bottom: $spacer--xl;

    @include media-breakpoint-down(s) {
      width: 100%;
      margin-bottom: $spacer--xs;
    }

    .c-cta__inner > .c-cta__content > .c-cta__button > .c-button {
      background-color: $mariner;
      color: $white;
      padding: $spacer--l $spacer;
      flex-direction: row;

      &:hover {
        background-color: $ocean;
        box-shadow: $box-shadow;
      }
    }
  }

  .c-homepage__buttons .l-container {
    padding-left: 0;
    padding-right: 0;
  }

  .c-media-block__media {
    margin-top: $spacer--xl;
    order: 2;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  @include media-breakpoint-down(s) {
    .c-cta__inner > .c-cta__content > .c-cta__button > .c-button {
      width: 100%;
    }
  }

  @include media-breakpoint-up(m) {
    .c-media-block__content .c-wysiwyg {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
    }

    .c-cta {
      display: flex;
      flex: 0 0 30.33%;

      .c-cta__inner {
        flex: 1;

        .c-cta__content > .c-cta__button > .c-button {
          display: flex;
          flex: 0 1 46.5%;
        }
      }
    }
  }

  @include media-breakpoint-up(l) {
    .c-media-block {
      flex-direction: row;
      margin-top: $spacer--2xl;
    }
    .c-media-block__media {
      order: 1;
      margin-top: 0;
      max-width: none;
    }
  }
}

.c-homepage__pas {
  background-color: $iceberg;
  padding: $spacer--2xl $spacer--l;
  border-radius: $spacer--s;
  margin-top: $spacer;
  text-align: center;
  position: relative;
  overflow: hidden;

  .c-media__title {
    width: 100%;
    padding-bottom: $spacer--l;
  }

  .c-content {
    z-index: 1;
    margin-top: $spacer--2xl;

    .c-figure {
      margin: 0;
    }
  }

  .c-profile-block {
    align-items: center;
    margin-top: $spacer--l;
    margin-bottom: $spacer--l;
  }

  .c-media {
    text-align: left;
    z-index: 1;

    .c-button {
      margin-top: $spacer;
      width: 100%;
      max-width: 30rem;
      @include media-breakpoint-up(l) {
        width: auto;
      }
    }
  }

  .c-blockquote {
    @include media-breakpoint-down(m) {
      .c-profile-block__content-top {
        justify-content: center;
        align-items: center;
      }
    }
  }

  @include media-breakpoint-down(m) {
    .c-blockquote__content {
      padding: 0;
    }
    .c-media {
      text-align: center;
    }
    .c-media .c-profile-block__content {
      padding-left: $spacer--m;
      text-align: left;
    }
  }
  @include media-breakpoint-up(m) {
    .c-media {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .c-profile-block {
      margin-top: $spacer;
      margin-bottom: $spacer;
      flex: 0 0 50%;
    }
    .c-button {
      width: auto;
    }
  }
  @include media-breakpoint-up(l) {
    margin-top: $spacer--2xl;
    display: flex;
    gap: $spacer;
    padding: $spacer--4xl $spacer--xl $spacer--3xl;
    align-items: center;
    text-align: left;

    .c-content {
      flex: 0 1 41.667%;
      margin-top: 0;
    }
    .c-media {
      flex: 0 1 58.333%;
      justify-content: space-between;
      padding-left: $spacer;
      .c-profile-block__content {
        padding-left: $spacer;
        padding-right: $spacer;
      }
    }
  }
}

.c-homepage__exclusive {
  position: relative;
  overflow: hidden;

  .l-container {
    position: relative;
    z-index: 1;
  }

  .c-section__header {
    text-align: center;
    @include media-breakpoint-up(l) {
      margin-bottom: $spacer--3xl;
    }
  }

  .c-related-subsection__header {
    display: flex;
    margin-top: $spacer--4xl;
    padding-bottom: $spacer--xl;
    margin-bottom: $spacer--xl;
    border-bottom: rem(1) solid #dcdcdc;
    h2 {
      flex: 99 1 auto;
      margin: 0;
    }
  }

  .c-video__details {
    @include media-breakpoint-up(l) {
      min-height: 9rem;
      position: relative;
    }
  }

  .c-video__info-container {
    flex-direction: column;
  }

  .c-video__logo {
    display: none;
  }

  @include media-breakpoint-up(l) {
    .c-media-block {
      flex-direction: row;
    }

    .c-media-block__subtitle {
      text-align: left;
    }
  }
}

.c-related-panel {
  background-color: $iceberg;
  padding: $spacer--2xl $spacer--l;
  margin-top: $spacer--2xl;
  text-align: center;
  h3 {
    margin-top: 0;
  }
  @include media-breakpoint-up(l) {
    padding: $spacer--4xl $spacer--xl $spacer--4xl;
    display: flex;
    align-items: center;
  }
  > .c-button {
    margin-top: $spacer--xl;
    width: 100%;
    max-width: 30rem;
    @include media-breakpoint-up(l) {
      display: none;
    }
  }
}

.c-related-panel__content {
  text-align: left;
  margin-bottom: $spacer--xl;
  p {
    line-height: 1.5;
    text-align: left;
  }
  .c-button {
    margin-top: $spacer--l;
    display: none;
    @include media-breakpoint-up(l) {
      display: inline-block;
    }
  }
  @include media-breakpoint-up(l) {
    flex: 1 1 40%;
    padding-right: $spacer;
    margin-bottom: 0;
  }
}
.c-related-panel__items {
  text-align: left;
  @include media-breakpoint-up(l) {
    @include make-grid(2, $spacer);
    flex: 0 1 60%;
  }
}

.c-related-panel__item {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;

  .c-related-panel__video {
    margin-bottom: $spacer--m;
    margin-top: $spacer--l;

    @include media-breakpoint-up(l) {
      margin-top: 0;
    }

    .c-video__image {
      height: 12rem;
    }
  }
  .c-post-block {
    margin: $spacer--l auto 0;
  }
  .c-video-list__title,
  .c-post-block__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    padding: 0;
    color: color-step($primary-dark, 800);
    @include type-scale(m, 1.1);
  }
  .c-video-list__subtitle,
  .c-post-block__after {
    margin-bottom: 0;
    margin-top: $spacer--xs;
    font-size: 1rem;
  }
  .c-post-block__content {
    padding-bottom: 0;
  }
  @include media-breakpoint-up(l) {
    padding: 0 $spacer--s;
    .c-post-block {
      margin-top: 0;
    }
  }
}

.c-post-block--horizontal .c-post-block__inner {
  height: 100%;
}

.c-related-subsection {
  text-align: center;

  .c-related-subsection__header,
  .c-related-posts {
    text-align: left;
  }

  .c-related-posts {
    margin-bottom: $spacer--4xl;
  }

  .c-post-block__image {
    height: rem(200);
    object-fit: cover;
  }
}

//News Events
.c-home__connect {
  .l-row {
    margin-left: 0;
    margin-right: 0;
  }

  .c-button--link {
    .c-icon-block {
      display: none;
    }
  }

  .left-column {
    @include make-col-ready(0);

    @include media-breakpoint-up(l) {
      @include make-col(8);
      padding-right: $spacer--l;
    }
  }

  .right-column {
    @include make-col-ready(0);
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(l) {
      @include make-col(4);
    }
  }

  .c-section__header {
    text-align: center;
    margin-bottom: $spacer--4xl;
  }

  .c-media-block {
    margin-bottom: $spacer--xl;

    h3 {
      margin-bottom: $spacer;
    }

    .c-wysiwyg p {
      margin-bottom: $spacer;
    }

    .cta-group {
      display: flex;
      flex-wrap: wrap;

      .c-cta {
        margin-right: $spacer;
        margin-bottom: $spacer;
        &:last-of-type {
          margin-right: 0;
        }
      }

      .c-cta__inner > .c-cta__content > .c-cta__button > .c-button {
        .o-icon {
          display: none;
        }
      }

      .c-cta__inner
        > .c-cta__content
        > .c-cta__button
        > .c-button--outline-white {
        background-color: transparent;
        border-color: $white;
        color: $white;

        &:hover {
          background-color: $white;
          border-color: $white;
          color: $primary-dark;
          box-shadow: $box-shadow;
        }
      }

      .c-cta__inner > .c-cta__content > .c-cta__button > .c-button--link-white {
        background-color: transparent;
        border-color: transparent;
        color: $white;
        margin-bottom: 0;

        &:hover {
          color: $secondary;
        }
      }

      .c-button {
        color: $primary;
        background-color: transparent;
        border-color: rgba($white, 0);

        &:hover {
          background-color: transparent;
          border-color: $primary;
        }
      }
    }

    .btn-group {
      .c-button {
        margin-right: $spacer;
        margin-bottom: $spacer;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .c-button--link-white {
    margin-bottom: $spacer--s;
  }

  .c-button--link-white:after {
    color: $secondary;
  }
  @include media-breakpoint-down(m) {
    .js-toggleLiveChat {
      display: none;
    }
  }

  @include media-breakpoint-up(m) {
    .c-media-block {
      display: flex;
    }
    .c-media-block__media {
      width: 33%;
    }
    .c-media-block__content {
      width: 66%;
      padding-left: $spacer;
    }
  }
}

.c-home__social {
  background-color: $white;
  border-radius: $border-radius;
  padding: $spacer--l;
  margin-top: $spacer--l;
  text-align: center;
  h3 {
    @include make-h4;
    margin-top: 0;
  }
  .c-social-links {
    margin-top: $spacer--l;
  }
  @include media-breakpoint-up(l) {
    padding: $spacer--3xl $spacer--xl;
  }
}

.c-home__events {
  background-color: $mariner;
  color: $white;
  border-radius: $border-radius;
  padding: $spacer--l;
  text-align: center;
  align-self: flex-start;

  @include media-breakpoint-up(l) {
    padding: $spacer--3xl $spacer--xl;
  }

  .c-events__heading {
    @include make-h4;
    color: $white;
    margin-bottom: $spacer--xl;
    margin-top: 0;
  }

  .c-button {
    width: 100%;
  }

  .c-events__item {
    @include make-col-ready($spacer);
  }

  .c-event-block {
    @include make-row;
    flex-wrap: nowrap;
    margin-bottom: $spacer--xl;
    align-items: center;
  }

  .c-event-block-container {
    @include make-col-ready($spacer);
    border-radius: rem(4);
    flex: 0 0 4.5rem;
    height: 4.5rem;
    position: relative;
    // This aligns the items in the circle horizontally & vertically
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light;
  }

  .c-event-block__content {
    @include make-col-ready($spacer);
    @include make-col(9);

    font-weight: 700;
    font-size: rem(17);
    line-height: 1.3;
    a {
      text-decoration: none;
      color: $white;
    }
  }

  .c-event-block__subtitle {
    font-weight: 400;
    font-size: 1rem;
  }

  .c-events__list {
    text-align: left;
    margin-bottom: $spacer--3xl;
  }

  .c-event-block__date {
    @include make-row;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: 1.2;
    color: $dark;
  }

  .c-event-block__month {
    @include make-col-ready($spacer);
    font-weight: 400;
  }

  .c-event-block__day {
    @include make-col-ready($spacer);
    font-weight: $font-weight--bold;

    font-size: rem(32);
  }
}
