@function get-type-scales-property($prop, $bp) {
  @return map-get(map-get($type-scales, $bp), $prop);
}

@mixin generate-type-styles($step, $line-height, $ratio, $base ) {
    // calc the font size in rem and round to the nearest whole pixel
    $fs: round( pow($ratio, $step) * strip-unit($base) * $base-font-size ) / $base-font-size  * 1rem;
    font-size: $fs;

    // calc the line height in base4 based off the font size;
    @if $line-height != false {
        $lh: base4($fs, $line-height);
        line-height: $lh;
    }
}

@mixin get-type-scale( $step: 0, $line-height: $line-height--base ) {
    @if $step < 0 {
        // hard setting the -1 and -2 steps int he type-scale
        @if $step == -1 {
            font-size: 0.875rem;
            @if $line-height != false {
                line-height: base4(0.875rem, $line-height);
            }
        } @else if $step == -2 {
            font-size: 0.75rem;
            @if $line-height != false {
                line-height: base4(0.75rem, $line-height);
            }
        } @else {
            @error "Invalid type scale step";
        }
    } @else {
        // set vars for previous breakpoints' base and ratios
        $prev-tsb: null;
        $prev-tsr: null;

        // loop throug all breakpoints in $type-scales
        @each $bp, $data in $type-scales {

            $bp-tsb: get-type-scales-property(base, $bp); // Breakpoint base font size
            $bp-tsr: get-type-scales-property(ratio, $bp); // Breakpoint ratio

            // make sure each breakpoint in $type-scales matches the breakpoints in $grid-breakpoints
            @if map-has-key($grid-breakpoints, $bp) {
                $output-bp: 1;

                // Check to see if breakpoint should be rendered
                // if step is zero, ratio does not matter,
                // check to see if the current base matches the previous base
                @if $step == 0 and $bp-tsb == $prev-tsb {
                    $output-bp: 0;
                // if step is greater than zero,
                // block output if current ratio and base match the previous ratio and base
                } @else if $step > 0 and $bp-tsb == $prev-tsb and $bp-tsr == $prev-tsr {
                    $output-bp: 0;
                }
                @if $output-bp == 1 {
                    // mobile first
                    @include media-breakpoint-up($bp) {
                        @include generate-type-styles($step, $line-height, $bp-tsr, $bp-tsb);
                    }
                }
            } @else {
                @warn "Invalid breakpoint in $type-scales: `#{$bp}`";
            }
            // update the previous breakpoint base and ratio values
            $prev-tsb: $bp-tsb;
            $prev-tsr: $bp-tsr;
        }
    }
}

@mixin type-scale( $size: base, $line-height: $line-height--base ) {
    @if map-has-key( $type-scale-sizes, $size ) {
        $step: map-get( $type-scale-sizes, $size );
        @include get-type-scale($step, $line-height);
    } @else {
        @warn "Size #{$size} was not found in $type-scale-sizes";
    }
}

@mixin make-h1() {
    @include get-styles-from-map($h1-styles);
}

@mixin make-h2() {
    @include get-styles-from-map($h2-styles);
}

@mixin make-h3() {
    @include get-styles-from-map($h3-styles);
}

@mixin make-h4() {
    @include get-styles-from-map($h4-styles);
}

@mixin make-h5() {
    @include get-styles-from-map($h5-styles);
}

@mixin make-h6() {
    @include get-styles-from-map($h6-styles);
}
