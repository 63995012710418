.gg {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}

.gg--check {
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg--check::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg--chevron-up,
.gg--chevron-down,
.gg--chevron-left,
.gg--chevron-right {
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg--chevron-up::after,
.gg--chevron-down::after,
.gg--chevron-left::after,
.gg--chevron-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}

.gg--chevron-down {
  transform: rotate(180deg);
}

.gg--chevron-left {
  transform: rotate(-90deg);
}

.gg--chevron-right {
  transform: rotate(90deg);
}

.gg--arrow-up,
.gg--arrow-down,
.gg--arrow-left,
.gg--arrow-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg--arrow-up::after, .gg--arrow-up::before,
.gg--arrow-down::after,
.gg--arrow-down::before,
.gg--arrow-left::after,
.gg--arrow-left::before,
.gg--arrow-right::after,
.gg--arrow-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}
.gg--arrow-up::after,
.gg--arrow-down::after,
.gg--arrow-left::after,
.gg--arrow-right::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
}
.gg--arrow-up::before,
.gg--arrow-down::before,
.gg--arrow-left::before,
.gg--arrow-right::before {
  width: 2px;
  height: 10px;
  left: 8px;
  background: currentColor;
}

.gg--arrow-down {
  transform: rotate(180deg);
}

.gg--arrow-left {
  transform: rotate(-90deg);
}

.gg--arrow-right {
  transform: rotate(90deg);
}

.gg--arrow-r-up,
.gg--arrow-r-down,
.gg--arrow-r-left,
.gg--arrow-r-right,
.gg--arrow-r-d-up-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg--arrow-r-up::after, .gg--arrow-r-up::before,
.gg--arrow-r-down::after,
.gg--arrow-r-down::before,
.gg--arrow-r-left::after,
.gg--arrow-r-left::before,
.gg--arrow-r-right::after,
.gg--arrow-r-right::before,
.gg--arrow-r-d-up-right::after,
.gg--arrow-r-d-up-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}
.gg--arrow-r-up::after,
.gg--arrow-r-down::after,
.gg--arrow-r-left::after,
.gg--arrow-r-right::after,
.gg--arrow-r-d-up-right::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 7px;
}
.gg--arrow-r-up::before,
.gg--arrow-r-down::before,
.gg--arrow-r-left::before,
.gg--arrow-r-right::before,
.gg--arrow-r-d-up-right::before {
  width: 2px;
  height: 16px;
  left: 10px;
  background: currentColor;
}

.gg--arrow-r-down {
  transform: rotate(180deg);
}

.gg--arrow-r-left {
  transform: rotate(-90deg);
}

.gg--arrow-r-right {
  transform: rotate(90deg);
}

.gg--arrow-r-d-up-right {
  transform: rotate(45deg);
}

.gg--ribbon-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
}

.gg--ribbon-up:before {
  width: 51%;
}

.gg--ribbon-up:after,
.gg--ribbon-up:before {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
}

.gg--ribbon-up:before {
  left: 0;
  border-top: 2px solid;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: skew(0deg, -30deg);
}

.gg--ribbon-up:after {
  right: 0;
  width: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: 2px solid;
  transform: skew(0deg, 30deg);
}

.gg--close {
  border: 2px solid transparent;
  border-radius: 40px;
}

.gg--close::after,
.gg--close::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 3px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px;
}

.gg--close::after {
  transform: rotate(-45deg);
}

.gg--search {
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
}

.gg--search::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 8px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 10px;
  left: 12px;
}

.gg--facebook {
  width: 20px;
  height: 20px;
}

.gg--facebook::after,
.gg--facebook::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}

.gg--facebook::before {
  width: 8px;
  height: 20px;
  border-left: 4px solid;
  border-top: 4px solid;
  border-top-left-radius: 5px;
  left: 6px;
}

.gg--facebook::after {
  width: 10px;
  height: 4px;
  background: currentColor;
  top: 7px;
  left: 4px;
  transform: skew(-5deg);
}

.gg--info {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}

.gg--info::after,
.gg--info::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}

.gg--info::after {
  bottom: 2px;
  height: 8px;
}

.gg--info::before {
  height: 2px;
  top: 2px;
}

.gg--link {
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
  margin: 9px 6px;
}

.gg--link::after,
.gg--link::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 10px;
  border: 2px solid;
  top: -4px;
}

.gg--link::before {
  border-right: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  left: -6px;
}

.gg--link::after {
  border-left: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  right: -6px;
}

.gg--maximize-alt {
  width: 14px;
  height: 14px;
  box-shadow: -6px -6px 0 -4px, 6px 6px 0 -4px, 6px -6px 0 -4px, -6px 6px 0 -4px;
}

.gg--maximize-alt::after,
.gg--maximize-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 22px;
  height: 2px;
  border-left: 8px solid;
  border-right: 8px solid;
  transform: rotate(-45deg);
  bottom: 6px;
  left: -4px;
}

.gg--maximize-alt::before {
  transform: rotate(45deg);
}

.gg--phone::after,
.gg--phone::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg--phone::after {
  width: 18px;
  height: 18px;
  border-top-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 12px;
  border-left: 4px solid;
  border-bottom: 4px solid;
  left: 2px;
  bottom: 2px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat right 11px/6px 4px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat -1px 0/4px 6px;
}

.gg--phone::before {
  width: 20px;
  height: 20px;
  border: 6px double;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  transform: rotate(-45deg);
  bottom: 2px;
  left: 2px;
}

.gg--twitter {
  width: 20px;
  height: 20px;
}

.gg--twitter::after,
.gg--twitter::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 4px;
}

.gg--twitter::before {
  width: 9px;
  height: 14px;
  border-left: 4px solid;
  border-bottom: 4px solid;
  border-bottom-left-radius: 6px;
  background: linear-gradient(to left, currentColor 12px, transparent 0) no-repeat center 2px/10px 4px;
  top: 4px;
}

.gg--twitter::after {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 20px;
  top: 2px;
  box-shadow: 7px 4px 0, 7px 12px 0;
}

.gg--information-circle {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}

.gg--information-circle::after,
.gg--information-circle::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}

.gg--information-circle::after {
  bottom: 2px;
  height: 8px;
}

.gg--information-circle::before {
  height: 2px;
  top: 2px;
}

.gg--check-box {
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--check-box::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg--radio-button-unselected {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}

.gg--radio-button-unselected-focused {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-radius: 100px;
}

.gg--radio-button-selected,
.gg--radio-button-selected::after {
  display: block;
  box-sizing: border-box;
  border-radius: 100px;
}

.gg--radio-button-selected {
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}

.gg--radio-button-selected::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: currentColor;
  top: 4px;
  left: 4px;
}

.gg--remove {
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 10px;
}

.gg--plus,
.gg--plus::after {
  border-radius: 10px;
}

.gg--plus {
  margin-top: -2px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
}

.gg--plus::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}

.gg--calendar,
.gg--calendar::before {
  display: block;
  box-sizing: border-box;
}

.gg--calendar {
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-top: 4px solid;
  border-radius: 3px;
}

.gg--calendar::before {
  content: "";
  position: absolute;
  width: 10px;
  border-radius: 3px;
  left: 2px;
  background: currentColor;
  height: 2px;
  top: 2px;
}

.gg--check-circle {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}

.gg--check-circle::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-color: currentColor;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg--time {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px currentColor;
}

.gg--time::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 7px;
  height: 7px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  top: 1px;
  left: 5px;
}

.gg--close-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--close-r::after,
.gg--close-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 3px;
}

.gg--close-r::after {
  transform: rotate(-45deg);
}

.gg--location-pin {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 100% 100% 0 100%;
  border: 2px solid;
  margin-top: -4px;
}

.gg--location-pin::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  top: 3px;
  left: 3px;
  border-radius: 40px;
}

.gg--mail,
.gg--mail::after {
  display: block;
  box-sizing: border-box;
  height: 14px;
  border: 2px solid;
}

.gg--mail {
  overflow: hidden;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 18px;
  border-radius: 2px;
}

.gg--mail::after {
  content: "";
  position: absolute;
  border-radius: 3px;
  width: 14px;
  transform: rotate(-45deg);
  bottom: 3px;
  left: 0;
}

.gg--ratio {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 20px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--ratio::after,
.gg--ratio::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
}

.gg--ratio::before {
  border-bottom: 2px solid;
  border-right: 2px solid;
  top: 8px;
  left: 12px;
}

.gg--ratio::after {
  border-top: 2px solid;
  border-left: 2px solid;
  top: 2px;
  left: 2px;
}

.gg--lock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 11px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -12px;
}

.gg--lock::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 10px;
  border-radius: 2px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  left: -4px;
  top: 9px;
}

.gg--menu {
  transform: scale(var(--ggs, 1));
}

.gg--menu,
.gg--menu::after,
.gg--menu::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}

.gg--menu::after,
.gg--menu::before {
  content: "";
  position: absolute;
  top: -6px;
}

.gg--menu::after {
  top: 6px;
}

.gg--pen {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 14px;
  height: 4px;
  border-right: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset -2px 0 0;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-right: -2px;
}

.gg--pen::after,
.gg--pen::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg--pen::before {
  background: currentColor;
  border-left: 0;
  right: -6px;
  width: 3px;
  height: 4px;
  border-radius: 1px;
  top: 0;
}

.gg--pen::after {
  width: 8px;
  height: 7px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 7px solid;
  left: -11px;
  top: -2px;
}

.gg--play-button {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}

.gg--play-button::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

.gg--plus,
.gg--plus::after {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 10px;
}

.gg--plus {
  margin-top: -2px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
}

.gg--plus::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}

.gg--printer {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 0 10px/6px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 14px 10px/6px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4px 4px/2px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
  border: 2px solid transparent;
  border-bottom: 0;
  box-shadow: inset 0 2px 0, inset 2px 2px 0, inset -2px 2px 0, inset -2px 2px 0;
}

.gg--printer::after,
.gg--printer::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  border: 2px solid;
  left: 4px;
}

.gg--printer::before {
  height: 6px;
  top: -4px;
}

.gg--printer::after {
  height: 8px;
  top: 8px;
}

.gg--chat {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-bottom: 0;
  box-shadow: -6px 8px 0 -6px, 6px 8px 0 -6px;
}

.gg--chat::after,
.gg--chat::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
}

.gg--chat::before {
  border: 2px solid;
  border-top-color: transparent;
  border-bottom-left-radius: 20px;
  right: 4px;
  bottom: -6px;
  height: 6px;
}

.gg--chat::after {
  height: 2px;
  background: currentColor;
  box-shadow: 0 4px 0 0;
  left: 4px;
  top: 4px;
}

.gg--external {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  box-shadow: -2px 2px 0 0, -4px -4px 0 -2px, 4px 4px 0 -2px;
  margin-left: -2px;
  margin-top: 1px;
}

.gg--external::after,
.gg--external::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: -4px;
}

.gg--external::before {
  background: currentColor;
  transform: rotate(-45deg);
  width: 12px;
  height: 2px;
  top: 1px;
}

.gg--external::after {
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  top: -4px;
}

.gg--link {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
}

.gg--link::after,
.gg--link::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 10px;
  border: 2px solid;
  top: -4px;
}

.gg--link::before {
  border-right: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  left: -6px;
}

.gg--link::after {
  border-left: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  right: -6px;
}

.gg--check-box-outline {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--check-box-outline-focused {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-radius: 4px;
}

.gg--bookmark,
.gg--bookmark::after {
  border-top-right-radius: 3px;
}

.gg--bookmark {
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 3px;
  overflow: hidden;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
}

.gg--bookmark::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  top: 9px;
  left: -1px;
}

.gg--plus-circle {
  border: 2px solid;
  border-radius: 22px;
}

.gg--plus-circle::after,
.gg--plus-circle::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}

.gg--plus-circle::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg--smartphone {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4px 12px/2px 2px;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
}

.gg--awards {
  display: block;
  width: 16px;
  height: 20px;
  border: 4px solid transparent;
  border-top: 12px solid transparent;
  border-bottom: 0;
  box-shadow: inset 4px 0 0 -2px, inset -4px 0 0 -2px;
}

.gg--awards::after,
.gg--awards::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  border: 2px solid;
}

.gg--awards::before {
  width: 14px;
  height: 14px;
  border-radius: 22px;
  top: -12px;
  left: -3px;
}

.gg--awards::after {
  width: 6px;
  height: 6px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-bottom-left-radius: 1px;
  border-top-right-radius: 1px;
  transform: rotate(45deg);
  bottom: -3px;
  left: 1px;
}

.gg--trophy {
  width: 10px;
  height: 12px;
  border: 2px solid;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.gg--trophy::after,
.gg--trophy::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg--trophy::before {
  box-shadow: inset 0 0 0 2px, 0 2px 0 0;
  bottom: -4px;
  width: 6px;
  height: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  left: 0;
}

.gg--trophy::after {
  width: 16px;
  height: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
  left: -5px;
  top: 0;
}

.gg--pin-alt {
  width: 2px;
  height: 8px;
  border-radius: 3px;
  transform: scale(var(--ggs, 1));
  background: currentColor;
}

.gg--pin-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 6px double;
  border-radius: 100px;
  top: -10px;
  left: -5px;
}

.gg--chevron-double-right::after,
.gg--chevron-double-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  right: 6px;
}

.gg--chevron-double-right::after {
  right: 11px;
}

.gg--readme {
  width: 2px;
  height: 17px;
  border-bottom: 4px solid;
  border-radius: 4px;
}

.gg--readme::after,
.gg--readme::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 12px;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  bottom: -1px;
  background: linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 5px/4px 2px, linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 2px/4px 2px, linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 8px/4px 2px;
}

.gg--readme::before {
  border-top-right-radius: 3px;
  left: -8px;
}

.gg--readme::after {
  border-top-left-radius: 3px;
  right: -8px;
}

.gg--play-button-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}

.gg--play-button-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

.gg--heart,
.gg--heart::after {
  border: 2px solid;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  width: 10px;
  height: 8px;
  border-bottom: 0;
}

.gg--heart {
  box-sizing: border-box;
  position: relative;
  transform: translate(calc(-5px * var(--ggs, 1)), calc(-3px * var(--ggs, 1))) rotate(-45deg) scale(var(--ggs, 1));
  display: block;
}

.gg--heart::after,
.gg--heart::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg--heart::after {
  right: -9px;
  transform: rotate(90deg);
  top: 5px;
}

.gg--heart::before {
  width: 11px;
  height: 11px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  left: -2px;
  top: 3px;
}

/* This is need for an ie11 bugfix where the videos are causing spacing issues at the bottom of the page*/
.home #content {
  overflow: hidden;
}

/* Removes arrow icon from hero CTA button */
.c-hero__main > .c-cta > .c-cta__inner > .c-cta__content > .c-cta__button > .c-button .o-icon {
  display: none;
}

.c-profile-block__name {
  font-size: unset;
}

.c-hero {
  padding-top: 3rem;
  position: relative;
  background-repeat: no-repeat;
  background-color: #ecf9fe;
}
@media (max-width: 34.9375em) {
  .c-hero {
    padding-bottom: 0;
  }
}
.c-hero .c-hero-img__mobile {
  display: block;
}
@media (min-width: 35em) {
  .c-hero .c-hero-img__mobile {
    display: none;
  }
}
@media (min-width: 35em) and (max-width: 66.6875em) {
  .c-hero .c-hero__main {
    text-align: center;
  }
}
.c-hero .c-hero__content {
  z-index: 2;
}
@media (max-width: 66.6875em) {
  .c-hero .c-hero__content {
    padding-bottom: 1.5rem;
  }
}
.c-hero .c-button {
  width: 100%;
  max-width: 27rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0.25rem 0.5rem rgba(12, 59, 102, 0.2);
}
.c-hero .c-action-list {
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 2;
}
@media (max-width: 34.9375em) {
  .c-hero .c-action-list {
    background-color: #ffffff;
  }
}
.c-hero .c-action-block__title {
  font-size: 1.25rem;
  display: block;
  margin-bottom: 0.75rem;
}
.c-hero .c-action-block__copy {
  font-size: 1rem;
  line-height: 1.3125rem;
}
.c-hero .c-action-block .o-image {
  display: block;
  min-width: 1.5rem;
  margin-top: 0.125rem;
}
@media (max-width: 66.6875em) {
  .c-hero .c-action-list {
    padding-top: 1.5rem;
  }
  .c-hero .c-action-block {
    background-color: #ffffff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    max-width: 27rem;
  }
}
@media (max-width: 34.9375em) {
  .c-hero .c-action-block {
    flex-direction: row;
    text-align: left;
    border-bottom: 0.0625rem solid #e4e4e6;
    border-radius: 0;
  }
  .c-hero .c-action-block .c-action-block__content {
    padding-left: 1.25rem;
  }
  .c-hero .c-action-list__item:last-of-type .c-action-block {
    border: none;
  }
}
@media (min-width: 66.75em) {
  .c-hero {
    background-image: url("/wp-content/uploads/homepage-hero-1.png");
    background-position: top right;
    padding-bottom: 0;
  }
  .c-hero .c-button {
    width: auto;
    margin-left: 0;
  }
  .c-hero .c-hero__after {
    background-color: #ffffff;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .c-hero .c-action-list {
    align-items: stretch;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
  }
  .c-hero .c-action-list__items {
    flex-direction: row;
  }
  .c-hero .c-action-list__item {
    flex: 0 1 33%;
    border-right: 0.0625rem solid #f0f3f5;
  }
  .c-hero .c-action-list__item:last-child {
    border: 0;
  }
  .c-hero .c-action-block__image {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .c-hero .c-action-block {
    border-radius: 0;
    padding: 0;
    margin: 0;
    text-align: left;
    flex-direction: row;
    align-items: flex-start;
  }
  .c-hero .c-action-block__title {
    font-size: 1.375rem;
  }
}
@media only screen and (min-width: 55rem) and (max-width: 60rem) {
  .c-hero {
    background-position: 170% 0;
  }
}
@media only screen and (min-width: 60.0625rem) and (max-width: 67.5rem) {
  .c-hero {
    background-position: 130% 0;
  }
}
@media only screen and (min-width: 125rem) {
  .c-hero {
    background-position: 75% 0;
  }
}
@media (min-width: 35em) and (max-width: 66.6875em) {
  .c-hero .c-action-list__items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .c-hero .c-action-list__item {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .c-hero .c-action-list__item:last-of-type .c-action-block {
    min-height: 9.0625rem;
    align-self: center;
  }
}
.c-hero .c-figure {
  margin: 0 auto;
}
.c-hero .c-video__link {
  display: flex;
  text-decoration: none;
  color: #3e4b52;
  align-items: center;
}
.c-hero .c-video__details {
  height: 5.25rem;
  flex: 0 0 7.0625rem;
  position: relative;
}
.c-hero .c-video__image {
  width: auto;
  height: 5.25rem;
  object-fit: cover;
}
.c-hero .c-video__content {
  padding-left: 1rem;
  position: relative;
  flex: 1 1 auto;
}
.c-hero .c-video__caption {
  text-align: left;
  margin: 0;
  color: #0c3b66;
  text-decoration: none;
  font-size: 1rem;
}
.c-hero .c-video__icon {
  --ggs: 1;
}

@media (min-width: 66.75em) {
  .c-hero__title,
  .c-hero__description {
    width: 50%;
    min-width: 36rem;
  }
}
@media (min-width: 35em) and (max-width: 74.9375em) {
  .c-hero__title,
  .c-hero__description {
    width: 40%;
    min-width: 34rem;
  }
}
@media (max-width: 66.6875em) {
  .c-hero__title,
  .c-hero__description {
    max-width: 100%;
    margin: 0 auto;
  }
}

.c-hero__description {
  margin-bottom: 2rem;
}

.c-page__content {
  background-color: #ffffff;
}

.c-homepage__buttons {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.c-homepage__buttons .c-section__content {
  display: flex;
  flex-direction: column;
}
.c-homepage__buttons .c-icon__text {
  display: flex;
  align-items: center;
}
.c-homepage__buttons .c-button {
  font-size: 1.125rem;
  color: #1871c3;
  line-height: 1.05;
  background-color: #ffffff;
  border: none;
  flex: 0 0 30.33%;
  margin-top: 0;
  margin-bottom: 3%;
  flex-direction: column;
  padding: 1.5rem 1rem;
  box-shadow: 0 0.25rem 1.25rem rgba(3, 28, 39, 0.2);
  text-transform: unset;
  letter-spacing: unset;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 34.9375em) {
  .c-homepage__buttons .c-button {
    margin-bottom: 5%;
  }
}
.c-homepage__buttons .c-button:hover {
  box-shadow: 0 0.5rem 2.5rem rgba(3, 28, 39, 0.2);
  transform: translateY(-0.25rem);
}
.c-homepage__buttons .c-button:hover .c-icon__text {
  color: #0c3b66;
}
.c-homepage__buttons .o-image {
  margin-bottom: 1rem;
  width: 2.5rem;
}
@media (min-width: 35em) {
  .c-homepage__buttons .c-section__content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-homepage__buttons .c-button {
    flex: 0 0 46%;
    margin-bottom: 4%;
  }
}
@media (min-width: 66.75em) {
  .c-homepage__buttons .c-section__content {
    margin-top: 3rem;
  }
  .c-homepage__buttons .c-button {
    font-size: 1.4375rem;
    flex: 0 0 30.33%;
    margin-top: 0;
    margin-bottom: 3%;
    padding: 1.5rem 1.25rem;
  }
}

.c-video {
  text-align: left;
}
.c-homepage__experience .c-video, .c-homepage__help .c-video {
  text-align: left;
}

.c-homepage__experience {
  position: relative;
  background-color: #ecf9fe;
  overflow: hidden;
}
.c-homepage__experience .l-container {
  position: relative;
  z-index: 1;
}
.c-homepage__experience .c-media-block__content .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}
.c-homepage__experience .c-media-block__content .c-wysiwyg p {
  margin-bottom: 0.25rem;
}
.c-homepage__experience .c-media-block__subtitle {
  color: #434552;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  margin-bottom: 0;
  text-align: center;
}
@media (max-width: 34.9375em) {
  .c-homepage__experience .c-media-block__subtitle {
    text-align: left;
  }
}
.c-homepage__experience .c-media-block__subtitle::before {
  content: "";
  display: block;
  background-color: #fda20b;
  height: 0.125rem;
  width: 3rem;
}
.c-homepage__experience .c-media-block__content {
  text-align: center;
  margin-bottom: 2.5rem;
}
@media (max-width: 34.9375em) {
  .c-homepage__experience .c-media-block__content {
    text-align: left;
  }
}
.c-homepage__experience .c-media-block__content p:not(.c-media-block__subtitle) {
  text-align: left;
}
@media (max-width: 66.6875em) {
  .c-homepage__experience .c-media-block__content .c-button {
    width: 100%;
    max-width: 30rem;
  }
}
@media (min-width: 66.75em) {
  .c-homepage__experience .c-media-block__content {
    text-align: left;
    margin-bottom: 0;
  }
}
.c-homepage__experience .c-media-block__content h2 {
  margin-top: 0;
}
.c-homepage__experience .c-media-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.c-homepage__experience .c-media-block--media-right .c-media-block__media {
  order: 2;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 34.9375em) {
  .c-homepage__experience .c-media-block--media-right .c-media-block__media {
    text-align: left;
  }
}
@media (min-width: 66.75em) {
  .c-homepage__experience .c-container .c-content p {
    font-size: 1.3125rem;
  }
}
@media (min-width: 66.75em) {
  .c-homepage__experience {
    background-image: url("/wp-content/themes/asbestos/assets/img/home/home-bg-plus-marks.svg");
    background-repeat: no-repeat;
    background-position: 5% 95%;
    background-size: 14.375rem;
  }
  .c-homepage__experience .c-media-block {
    flex-direction: row;
  }
  .c-homepage__experience .c-media-block__subtitle {
    text-align: left;
  }
  .c-homepage__experience .c-media-block--media-right .c-media-block__media {
    max-width: none;
  }
}

.c-homepage__teams {
  background-color: #ffffff;
  padding: 2.5rem 2rem 2rem;
  border-radius: 0.75rem;
  margin-top: 1rem;
  text-align: center;
}
.c-homepage__teams h3 {
  font-size: 2.1875rem;
  margin-top: 0;
}
.c-homepage__teams .c-content p {
  line-height: 1.4;
  font-size: 1.125rem;
  text-align: left;
}
.c-homepage__teams .c-profile-block {
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.c-homepage__teams .c-profile-block__content {
  padding-left: 1.25rem;
}
.c-homepage__teams .c-profile-block__name {
  font-size: 1.4rem;
}
.c-homepage__teams .c-profile-block__title {
  font-style: italic;
}
.c-homepage__teams .c-media {
  text-align: left;
}
.c-homepage__teams > .c-button {
  margin-top: 1.5rem;
  display: inline-block;
  width: 100%;
  max-width: 30rem;
}
@media (max-width: 66.6875em) {
  .c-homepage__teams .c-content .c-button {
    display: none;
  }
}
@media (min-width: 35em) {
  .c-homepage__teams .c-media {
    display: flex;
    flex-wrap: wrap;
  }
  .c-homepage__teams .c-profile-block {
    flex: 0 0 50%;
  }
}
@media (min-width: 66.75em) {
  .c-homepage__teams {
    margin-top: 2.5rem;
    display: flex;
    padding: 2rem;
    align-items: center;
    text-align: left;
  }
  .c-homepage__teams h3 {
    font-size: 1.5rem;
  }
  .c-homepage__teams .c-content {
    flex: 0 1 41.667%;
  }
  .c-homepage__teams .c-media {
    flex: 0 1 58.333%;
    padding-left: 2rem;
  }
  .c-homepage__teams .c-profile-block {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .c-homepage__teams .c-profile-block__name {
    font-size: 1.375rem;
  }
  .c-homepage__teams .c-profile-block__content {
    padding-left: 1rem;
  }
  .c-homepage__teams > .c-button {
    display: none;
  }
}

.c-home__featured-in {
  text-align: center;
}
.c-home__featured-in h3 {
  margin-top: 4rem;
}

.c-home__featured-in__logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.c-home__featured-in__logos .c-home__logo-container {
  flex: 0 1 auto;
  padding: 1rem;
}
@media (min-width: 75em) {
  .c-home__featured-in__logos {
    flex-wrap: nowrap;
  }
}

.c-section--primary-dark {
  background-color: #0c3b66;
}

@media (max-width: 66.6875em) {
  .c-homepage__help {
    text-align: left;
  }
}
.c-homepage__help .c-media-block {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.c-homepage__help .c-media-block__subtitle {
  color: #434552;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  margin-bottom: 0;
  text-align: center;
}
@media (max-width: 34.9375em) {
  .c-homepage__help .c-media-block__subtitle {
    text-align: left;
  }
}
.c-homepage__help .c-media-block__subtitle::before {
  content: "";
  display: block;
  background-color: #fda20b;
  height: 0.125rem;
  width: 3rem;
}
.c-homepage__help .c-cta {
  margin-bottom: 2rem;
}
@media (max-width: 34.9375em) {
  .c-homepage__help .c-cta {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
.c-homepage__help .c-cta .c-cta__inner > .c-cta__content > .c-cta__button > .c-button {
  background-color: #1871c3;
  color: #ffffff;
  padding: 1.5rem 1rem;
  flex-direction: row;
}
.c-homepage__help .c-cta .c-cta__inner > .c-cta__content > .c-cta__button > .c-button:hover {
  background-color: #0c3b66;
  box-shadow: 0 0.25rem 1.25rem rgba(3, 28, 39, 0.2);
}
.c-homepage__help .c-homepage__buttons .l-container {
  padding-left: 0;
  padding-right: 0;
}
.c-homepage__help .c-media-block__media {
  margin-top: 2rem;
  order: 2;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}
@media (max-width: 34.9375em) {
  .c-homepage__help .c-cta__inner > .c-cta__content > .c-cta__button > .c-button {
    width: 100%;
  }
}
@media (min-width: 35em) {
  .c-homepage__help .c-media-block__content .c-wysiwyg {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
  }
  .c-homepage__help .c-cta {
    display: flex;
    flex: 0 0 30.33%;
  }
  .c-homepage__help .c-cta .c-cta__inner {
    flex: 1;
  }
  .c-homepage__help .c-cta .c-cta__inner .c-cta__content > .c-cta__button > .c-button {
    display: flex;
    flex: 0 1 46.5%;
  }
}
@media (min-width: 66.75em) {
  .c-homepage__help .c-media-block {
    flex-direction: row;
    margin-top: 2.5rem;
  }
  .c-homepage__help .c-media-block__media {
    order: 1;
    margin-top: 0;
    max-width: none;
  }
}

.c-homepage__pas {
  background-color: #ecf9fe;
  padding: 2.5rem 1.5rem;
  border-radius: 0.75rem;
  margin-top: 1rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.c-homepage__pas .c-media__title {
  width: 100%;
  padding-bottom: 1.5rem;
}
.c-homepage__pas .c-content {
  z-index: 1;
  margin-top: 2.5rem;
}
.c-homepage__pas .c-content .c-figure {
  margin: 0;
}
.c-homepage__pas .c-profile-block {
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.c-homepage__pas .c-media {
  text-align: left;
  z-index: 1;
}
.c-homepage__pas .c-media .c-button {
  margin-top: 1rem;
  width: 100%;
  max-width: 30rem;
}
@media (min-width: 66.75em) {
  .c-homepage__pas .c-media .c-button {
    width: auto;
  }
}
@media (max-width: 66.6875em) {
  .c-homepage__pas .c-blockquote .c-profile-block__content-top {
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 66.6875em) {
  .c-homepage__pas .c-blockquote__content {
    padding: 0;
  }
  .c-homepage__pas .c-media {
    text-align: center;
  }
  .c-homepage__pas .c-media .c-profile-block__content {
    padding-left: 1.25rem;
    text-align: left;
  }
}
@media (min-width: 35em) {
  .c-homepage__pas .c-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .c-homepage__pas .c-profile-block {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex: 0 0 50%;
  }
  .c-homepage__pas .c-button {
    width: auto;
  }
}
@media (min-width: 66.75em) {
  .c-homepage__pas {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    padding: 4rem 2rem 3rem;
    align-items: center;
    text-align: left;
  }
  .c-homepage__pas .c-content {
    flex: 0 1 41.667%;
    margin-top: 0;
  }
  .c-homepage__pas .c-media {
    flex: 0 1 58.333%;
    justify-content: space-between;
    padding-left: 1rem;
  }
  .c-homepage__pas .c-media .c-profile-block__content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.c-homepage__exclusive {
  position: relative;
  overflow: hidden;
}
.c-homepage__exclusive .l-container {
  position: relative;
  z-index: 1;
}
.c-homepage__exclusive .c-section__header {
  text-align: center;
}
@media (min-width: 66.75em) {
  .c-homepage__exclusive .c-section__header {
    margin-bottom: 3rem;
  }
}
.c-homepage__exclusive .c-related-subsection__header {
  display: flex;
  margin-top: 4rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 0.0625rem solid #dcdcdc;
}
.c-homepage__exclusive .c-related-subsection__header h2 {
  flex: 99 1 auto;
  margin: 0;
}
@media (min-width: 66.75em) {
  .c-homepage__exclusive .c-video__details {
    min-height: 9rem;
    position: relative;
  }
}
.c-homepage__exclusive .c-video__info-container {
  flex-direction: column;
}
.c-homepage__exclusive .c-video__logo {
  display: none;
}
@media (min-width: 66.75em) {
  .c-homepage__exclusive .c-media-block {
    flex-direction: row;
  }
  .c-homepage__exclusive .c-media-block__subtitle {
    text-align: left;
  }
}

.c-related-panel {
  background-color: #ecf9fe;
  padding: 2.5rem 1.5rem;
  margin-top: 2.5rem;
  text-align: center;
}
.c-related-panel h3 {
  margin-top: 0;
}
@media (min-width: 66.75em) {
  .c-related-panel {
    padding: 4rem 2rem 4rem;
    display: flex;
    align-items: center;
  }
}
.c-related-panel > .c-button {
  margin-top: 2rem;
  width: 100%;
  max-width: 30rem;
}
@media (min-width: 66.75em) {
  .c-related-panel > .c-button {
    display: none;
  }
}

.c-related-panel__content {
  text-align: left;
  margin-bottom: 2rem;
}
.c-related-panel__content p {
  line-height: 1.5;
  text-align: left;
}
.c-related-panel__content .c-button {
  margin-top: 1.5rem;
  display: none;
}
@media (min-width: 66.75em) {
  .c-related-panel__content .c-button {
    display: inline-block;
  }
}
@media (min-width: 66.75em) {
  .c-related-panel__content {
    flex: 1 1 40%;
    padding-right: 1rem;
    margin-bottom: 0;
  }
}

.c-related-panel__items {
  text-align: left;
}
@media (min-width: 66.75em) {
  .c-related-panel__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    flex: 0 1 60%;
  }
  .cssgridlegacy .c-related-panel__items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -1%;
    margin-right: -1%;
  }
  .cssgridlegacy .c-related-panel__items > * {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2%;
  }
  .cssgridlegacy .c-related-panel__items > * > * {
    max-width: 100%;
  }
}

.c-related-panel__item {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}
.c-related-panel__item .c-related-panel__video {
  margin-bottom: 1.25rem;
  margin-top: 1.5rem;
}
@media (min-width: 66.75em) {
  .c-related-panel__item .c-related-panel__video {
    margin-top: 0;
  }
}
.c-related-panel__item .c-related-panel__video .c-video__image {
  height: 12rem;
}
.c-related-panel__item .c-post-block {
  margin: 1.5rem auto 0;
}
.c-related-panel__item .c-video-list__title,
.c-related-panel__item .c-post-block__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  padding: 0;
  color: rgb(7, 31, 53);
  font-size: 1.25rem;
  line-height: 1.2;
}
.c-related-panel__item .c-video-list__subtitle,
.c-related-panel__item .c-post-block__after {
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 1rem;
}
.c-related-panel__item .c-post-block__content {
  padding-bottom: 0;
}
@media (min-width: 66.75em) {
  .c-related-panel__item {
    padding: 0 0.75rem;
  }
  .c-related-panel__item .c-post-block {
    margin-top: 0;
  }
}

.c-post-block--horizontal .c-post-block__inner {
  height: 100%;
}

.c-related-subsection {
  text-align: center;
}
.c-related-subsection .c-related-subsection__header,
.c-related-subsection .c-related-posts {
  text-align: left;
}
.c-related-subsection .c-related-posts {
  margin-bottom: 4rem;
}
.c-related-subsection .c-post-block__image {
  height: 12.5rem;
  object-fit: cover;
}

.c-home__connect .l-row {
  margin-left: 0;
  margin-right: 0;
}
.c-home__connect .c-button--link .c-icon-block {
  display: none;
}
.c-home__connect .left-column {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0;
  padding-left: 0;
}
@media (min-width: 66.75em) {
  .c-home__connect .left-column {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 1.5rem;
  }
}
.c-home__connect .right-column {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0;
  padding-left: 0;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 66.75em) {
  .c-home__connect .right-column {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.c-home__connect .c-section__header {
  text-align: center;
  margin-bottom: 4rem;
}
.c-home__connect .c-media-block {
  margin-bottom: 2rem;
}
.c-home__connect .c-media-block h3 {
  margin-bottom: 1rem;
}
.c-home__connect .c-media-block .c-wysiwyg p {
  margin-bottom: 1rem;
}
.c-home__connect .c-media-block .cta-group {
  display: flex;
  flex-wrap: wrap;
}
.c-home__connect .c-media-block .cta-group .c-cta {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.c-home__connect .c-media-block .cta-group .c-cta:last-of-type {
  margin-right: 0;
}
.c-home__connect .c-media-block .cta-group .c-cta__inner > .c-cta__content > .c-cta__button > .c-button .o-icon {
  display: none;
}
.c-home__connect .c-media-block .cta-group .c-cta__inner > .c-cta__content > .c-cta__button > .c-button--outline-white {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.c-home__connect .c-media-block .cta-group .c-cta__inner > .c-cta__content > .c-cta__button > .c-button--outline-white:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0c3b66;
  box-shadow: 0 0.25rem 1.25rem rgba(3, 28, 39, 0.2);
}
.c-home__connect .c-media-block .cta-group .c-cta__inner > .c-cta__content > .c-cta__button > .c-button--link-white {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
  margin-bottom: 0;
}
.c-home__connect .c-media-block .cta-group .c-cta__inner > .c-cta__content > .c-cta__button > .c-button--link-white:hover {
  color: #fda20b;
}
.c-home__connect .c-media-block .cta-group .c-button {
  color: #1871c3;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
}
.c-home__connect .c-media-block .cta-group .c-button:hover {
  background-color: transparent;
  border-color: #1871c3;
}
.c-home__connect .c-media-block .btn-group .c-button {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.c-home__connect .c-media-block .btn-group .c-button:last-of-type {
  margin-right: 0;
}
.c-home__connect .c-button--link-white {
  margin-bottom: 0.75rem;
}
.c-home__connect .c-button--link-white:after {
  color: #fda20b;
}
@media (max-width: 66.6875em) {
  .c-home__connect .js-toggleLiveChat {
    display: none;
  }
}
@media (min-width: 35em) {
  .c-home__connect .c-media-block {
    display: flex;
  }
  .c-home__connect .c-media-block__media {
    width: 33%;
  }
  .c-home__connect .c-media-block__content {
    width: 66%;
    padding-left: 1rem;
  }
}

.c-home__social {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
}
.c-home__social h3 {
  font-family: "Palatino", "Palatino Linotype", "Palatino LT STD", "Book Antiqua", "Georgia", serif;
  color: #080a16;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-top: 0;
}
@media (min-width: 35em) {
  .c-home__social h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
.c-home__social .c-social-links {
  margin-top: 1.5rem;
}
@media (min-width: 66.75em) {
  .c-home__social {
    padding: 3rem 2rem;
  }
}

.c-home__events {
  background-color: #1871c3;
  color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: center;
  align-self: flex-start;
}
@media (min-width: 66.75em) {
  .c-home__events {
    padding: 3rem 2rem;
  }
}
.c-home__events .c-events__heading {
  font-family: "Palatino", "Palatino Linotype", "Palatino LT STD", "Book Antiqua", "Georgia", serif;
  color: #080a16;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
  margin-bottom: 2rem;
  margin-top: 0;
}
@media (min-width: 35em) {
  .c-home__events .c-events__heading {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
.c-home__events .c-button {
  width: 100%;
}
.c-home__events .c-events__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.c-home__events .c-event-block {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  flex-wrap: nowrap;
  margin-bottom: 2rem;
  align-items: center;
}
.c-home__events .c-event-block-container {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  flex: 0 0 4.5rem;
  height: 4.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f3f5;
}
.c-home__events .c-event-block__content {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 75%;
  max-width: 75%;
  font-weight: 700;
  font-size: 1.0625rem;
  line-height: 1.3;
}
.c-home__events .c-event-block__content a {
  text-decoration: none;
  color: #ffffff;
}
.c-home__events .c-event-block__subtitle {
  font-weight: 400;
  font-size: 1rem;
}
.c-home__events .c-events__list {
  text-align: left;
  margin-bottom: 3rem;
}
.c-home__events .c-event-block__date {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 1.2;
  color: rgb(6, 21, 37);
}
.c-home__events .c-event-block__month {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-weight: 400;
}
.c-home__events .c-event-block__day {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-weight: 700;
  font-size: 2rem;
}