/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container() {
    width: 100%;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    margin-right: auto;
    margin-left: auto;
}

@mixin make-row($gutter: $grid-gutter-width) {
    display: flex;
    flex-wrap: wrap;
    margin-right: ($gutter / -2);
    margin-left: ($gutter / -2);
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: rem(1); // Prevent collapsing
    padding-right: ($gutter / 2);
    padding-left: ($gutter / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage($size / $columns);
}


@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: $size / $columns;
    margin-left: if($num == 0, 0, percentage($num));
}


// used to be grid-cols
@mixin make-grid($count: 2, $gutter-x: 2rem, $gutter-y: $gutter-x) {
    display: grid;
    grid-template-columns: repeat($count, 1fr);
    grid-row-gap: $gutter-y;
    grid-column-gap: $gutter-x;
    
    // ie11
    .cssgridlegacy &  {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-left: -1%;
        margin-right: -1%;

        & > * {
            width: (percentage(1/$count) - 2%);
            margin-left: 1%;
            margin-right: 1%;
            margin-bottom: 2%;

            & > * {
                max-width: 100%;
            }
        }
    }
}
