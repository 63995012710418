$white: #ffffff;
$gray: #3e4b52;
$black: #000000;

//Grayscale
$gray-100: #0d1020;
$gray-90: #282b39;
$gray-80: #434552;
$gray-70: #5e606a;
$gray-60: #797a83;
$gray-50: #93959c;
$gray-40: #aeafb5;
$gray-30: #c9cacd;
$gray-20: #e4e4e6;

//New Colors
$ocean: #0c3b66;
$deep-ocean: #061726;
$mariner: #1871c3;
$clean-pool: #57c3f1;
$iceberg: #ecf9fe;

$carrot: #ff851b;
$squash: #fda20b;
$mango: $squash;
$peach: $squash;
$broccolini: #1d815b;

$primary: $mariner;
$primary-light: $clean-pool; // light blue
$primary-dark: $ocean; // dark blue
$secondary: $squash; // orange
$secondary-light: $squash; // light orange
$secondary-squash: $squash;

$success: $broccolini; // green
$error: #bf3f36; // red
$light: #f0f3f5;
$dark: color-step($primary-dark, 900);

$theme-colors: (
  'primary-light': $mariner,
  'primary-dark': $ocean,
  'secondary-light': $squash,
);

$site-max-width: 80rem;
$c-max-width: 48rem;

//Spacer Guide
$spacer--3xs: 0.125rem; // 2px
$spacer--2xs: 0.25rem; // 4px
$spacer--xs: 0.5rem; // 8px
$spacer--s: 0.75rem; // 12px
$spacer: 1rem; // 16px
$spacer--m: 1.25rem; // 20px
$spacer--l: 1.5rem; // 24px
$spacer--xl: 2rem; // 32px
$spacer--2xl: 2.5rem; // 40px
$spacer--3xl: 3rem; // 48px
$spacer--4xl: 4rem; // 64px
$spacer--5xl: 5rem; // 80px
$spacer--6xl: 6rem; // 96px
$spacer--7xl: 7rem; // 112px
$spacer--8xl: 8rem; // 128px

$grid-breakpoints: (
  s: 0,
  m: 35em,
  l: 66.75em,
  xl: 75em,
);

//Box Shadow
$box-shadow: 0 0.25rem 1.25rem rgba(3, 28, 39, 0.2);
$box-shadow-dark: 0 0.5rem 2.5rem rgba(3, 28, 39, 0.2);
$box-shadow-light: 0 0.25rem 1rem rgba(189, 189, 189, 0.2);

//Reset
$body-color: #2f3038;
// $base-font-size: 16 !default;

//Type
$font-family--sans-serif: 'proxima-nova', 'Arial', sans-serif;
$font-family--serif: 'Palatino', 'Palatino Linotype', 'Palatino LT STD',
  'Book Antiqua', 'Georgia', serif;
$font-family--secondary: $font-family--sans-serif;
$font-weight--semi-bold: 500;

$line-height--base: 30px;

//Type Scale - http://spencermortensen.com/articles/typographic-scale/
// https://utopia.fyi/calculator/?c=320,16,1.11,1200,18,1.125,8,2
$type-scale-base--s: 1;
$type-scale-base--m: 1.125;
$type-scale-base--l: 1.125;
$type-scale-base--xl: 1.125;

//type scale ratio for breakpoints
$type-scale-ratio--s: 1.07;
$type-scale-ratio--m: 1.07;
$type-scale-ratio--l: 1.125; // large tablet/small desktop - Major 2nd
$type-scale-ratio--xl: 1.125; // large desktop - Major 2nd

$type-scale-sizes: (
  xs: -2,
  // 14px
  s: -1,
  // 16px
  base: 0,
  // 18px
  m: 1,
  // 20px
  l: 3,
  // 26px
  xl: 5,
  // 32px
  2xl: 7,
  // 41px
  3xl: 8,
  // 48px
);

$type-scales: (
  s: (
    base: $type-scale-base--l,
    ratio: $type-scale-ratio--l,
  ),
  l: (
    base: $type-scale-base--l,
    ratio: $type-scale-ratio--l,
  ),
);

//Heading
$heading-margin-top: 0;
$heading-margin-bottom: 0;
$heading-color: #080a16;

$hummingbird-styles: (
  type-scale: base,
  font-family: $font-family--sans-serif,
);

//Caption
$caption-styles: (
  type-scale: s,
  color: color-step($gray, 400),
  font-style: normal,
  text-align: center,
);

//Disclaimer
$disclaimer-styles: (
  margin-left: auto,
  margin-right: auto,
  color: $primary,
);

$border-radius--s: 0.25rem;
$border-radius: 0.5rem;
$border-radius--c: 50%;
$border-radius--pill: 2rem;

$border-size--s: 0.0625rem;

//link
$link-color: $primary;
$link-decoration: none;
$link-hover-color: $primary-dark;
$link-hover-decoration: none;

//Forms
$form-label-styles: (
  font-weight: normal,
);

$form-control-styles: (
  padding: 0 $spacer,
  color: $gray-70,
  border-radius: $border-radius--s,
  border: 1px solid rgba(#2F3038, 0.5),
);

$form-control-error-styles: (
  border-color: $error,
);

//Card Styles
$card-shadow-distance: 0.25rem;
$card-shadow-blur: 1.25rem;
$card-shadow-spread: 0;
$card-shadow-color: rgba(#031c27, 0.2);
$card-border-radius: 0.5rem;

//Expand Menus
$expand-menu-link-d1-border-hover: $primary-dark;
